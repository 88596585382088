<template>
    <div class="alert-container alert-radius">
      <div class="d-flex align-center justify-space-between px-3 py-2">
        <span class="medium-font info--text" v-text="error.error.message"></span>
        <v-btn small text color="info" @click="update" v-if="error.error.action == 'update'" class="mr-auto">بروزرسانی</v-btn>
        <v-btn small text color="info" @click="removeProduct" v-if="error.error.action != 'update'" class="mr-auto">حذف</v-btn>
      </div>
    </div>
</template>

<script>
import { UpdateBasket, removeTObasket } from '@/views/shop/Basket/models/Basket'
export default {
  props: ['error', 'total'],
  methods: {
    update() {
        UpdateBasket({product: { product_id: this.$props.error.id ,
          count: this.$props.error.count, org_id: this.$props.error.org_id },
          price: '0', type: '-'})
    },
    removeProduct () {
      removeTObasket({ product_id: this.$props.error.id, org_id: this.$props.error.org_id, price: this.$props.total})
    }
  }
}
</script>