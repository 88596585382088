<template>
  <v-card class="card pa-3 mx-3 flat-card" color="surface">
    <v-row>
      <v-col>
        <span class="medium-font onSurface--text">
          برای ادامه فرایند خرید لطفا ابتدا اطلاعات کاربری خود را تکمیل کنید
        </span>
      </v-col>
    </v-row>
    
    <v-row class="pa-2">
      <v-spacer></v-spacer>
      <v-btn @click="go_profile" class="button onPrimaryActions--text primaryActions small-button">تکمیل پروفایل</v-btn>
    </v-row>
  </v-card>
</template>
<script>
export default {
  methods: {
    go_profile () {
      localStorage.setItem('from_basket', "from_basket")
      this.$router.push('/profile')
    }
  }
}
</script>