<template>
  <div>
    <v-btn
      v-text="title"
      type="button"
      @click="shoping"
      block
      class="fixed-btn py-6 button primaryActions onPrimaryActions--text">
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.fixed-btn{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 300;
}
</style>
<script>
import router from '@/router'
import { mapState } from 'vuex'
export default {
  props: ['title'],
  computed: {
    ...mapState({
      purses: state => state.basket.purses,
      coupons: state => state.basket.coupons
    })
  },
  methods: {
    shoping () {
      router.push('/')
    }
  }
}
</script>