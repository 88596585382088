<template>
  <div>
    <v-btn text fab dark x-small :color="color">
      <v-icon size="20">{{icon}}</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
    props: ['icon', 'color']
}
</script>